<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="min" :model="searchForm" ref="searchForm">
          <el-row>
            <el-col :md="8">
              <el-form-item label="非毛绒委托单号:">
                <el-input size="small" v-model.trim="searchForm.requ_no" clearable placeholder="请填写非毛绒委托单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="我司货号:">
                <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写我司货号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="模具名称:">
                <el-input size="small" v-model.trim="searchForm.mdnc_name" clearable placeholder="请填写中文品名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="模具编号:">
                <el-input size="small" v-model.trim="searchForm.mdin_no" clearable placeholder="请填写模具编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购经办人:">
                <el-select filterable v-model="searchForm.mdnc_stff_name" placeholder="请选择采购人员" size="small" clearable>
                  <el-option v-for="(item, index) in stffType" :key="index" :label="item.stff_name" :value="item.stff_name"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="计划完成日期:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="timeValue"
                    type="daterange"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMdopsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="addRow()" :disabled="!btn.add">新增</el-button>
        <el-button type="danger" icon="el-icon-delete" size="small" @click="doDelete()" :disabled="!btn.delete">删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            class="vg_cursor"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="产品图片" prop="imge_url" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.imge_url">
                  <el-image class="vd_elimg" :src="formatPic(scope, 's')" fit="fill" />
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="非毛绒委托打样单号" prop="requ_no" min-width="100px" align="center" />
            <el-table-column label="我司货号" prop="smpl_no" align="center" />
            <el-table-column label="模具名称" prop="mdnc_name" align="center" />
            <el-table-column
              label="计划完成日期"
              prop="mdnc_pldate"
              align="center"
              :formatter="({ mdnc_pldate }) => getDateNoTime(mdnc_pldate, false)"
            >
            </el-table-column>
            <el-table-column label="模具编号" prop="mdnc_no" align="center"> </el-table-column>
            <el-table-column label="外销员" prop="export_name" align="center" />
            <el-table-column label="采购经办人" prop="purchase_name" align="center" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" align="center" />
            <el-table-column
              label="录入时间"
              prop="create_time"
              :formatter="({ create_time }) => getDateNoTime(create_time, true)"
              align="center"
            />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template v-slot="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                  {{ helper.getStatusName(scope.row.status).name }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdopAPI } from '@api/modules/mdop';
import { userAPI } from '@api/modules/user';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import { getDateNoTime } from '@assets/js/dateUtilsTwo.js';

export default {
  name: 'MdopList',
  components: {
    pubPagination
  },
  computed: {},
  data() {
    return {
      timeValue: [],
      tableData: [],
      searchForm: {},
      totalPage: 0,
      btn: {},
      multiSelection: [],
      currentPage: 1,
      stffType: [],
      loadFlag: true,
      mouldTextList: [
        { id: 1, label: '橡胶:01' },
        { id: 2, label: 'PA6:02' },
        { id: 3, label: 'TPR:03' },
        { id: 4, label: 'TPE:04' },
        { id: 5, label: 'TPU:05' },
        { id: 6, label: 'PP:06' },
        { id: 7, label: 'ABS:07' },
        { id: 8, label: 'PET:08' },
        { id: 9, label: '吹塑EVA:09' },
        { id: 10, label: '射出EVA:10' }
      ],
      mouldTypeList: [
        { id: 0, label: '生产模' },
        { id: 1, label: '样品模' }
      ],
      mouldProdList: [
        { id: 0, label: '模房' },
        { id: 1, label: '工厂' }
      ]
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 样品类型转换
    formatSmplType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    },
    formatDate1(row) {
      return helper.toTimeDay(row);
    },
    formatProducer(row) {
      if (row === 0) {
        return '模房';
      } else if (row === 1) {
        return '工厂';
      }
    },
    formatType(row) {
      if (row === 0) {
        return '生产模';
      } else if (row === 1) {
        return '样品模';
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mdnc_edit' || from.path === '/mdnc_add') {
        this.initData();
      }
    }
  },
  methods: {
    getDateNoTime,
    initData() {
      this.loadFlag = false;
      // this.getOptnByPermId();
      this.getMdopsList();
      this.getStffType();
    },
    getMdopsList() {
      this.searchForm.page_no = this.currentPage;
      let [start, end] = this.timeValue;
      if (start) this.searchForm.start_time = start;
      if (end) this.searchForm.end_time = end;
      get(mdopAPI.getMdops, this.searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    /*		getOptnByPermId() {
			get(optnAPI.getOptnByPermId, {perm_id: 10009})
					.then((res) => {
						if (res.data.code === 0) {
							this.deptList = res.data.data.form.optn_cntt_list;
							let temp = {}
							temp.id = 0;
							temp.param1 = '全部'
							temp.param2 = ''
							this.deptList.unshift(temp)
						} else {
							this.$message.error(res.data.msg);
						}
					})
					.catch((res) => {
						this.$message.error(res.data.msg);
					});
		},*/
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      this.timeValue = [];
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMdopsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMdopsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mdnc_edit', { perm_id: permId, form_id: row.mdnc_id });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mdnc_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMdopsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formDeliTime(row) {
      return this.helper.toTimeDay(row.mdop_deli_time);
    },
    // 模具种类回显
    famaMindKind(row) {
      switch (row.mdop_kind) {
        case 0:
          return '橡胶成型模';
        case 1:
          return '橡胶硫化模';
        case 2:
          return '注塑模';
        case 3:
          return '热合模';
        case 4:
          return '辅助工具';
      }
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'warning', message: '已生效的数据不可删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = [];
        this.multiSelection.map(item => {
          ids.push(item.mdnc_id);
        });
        post(mdopAPI.deleteMdopByIds, { mdnc_id_list: ids }).then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
          }
          this.initData();
        });
      });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.searchForm.mdop_sort = 'asc';
        if (val.prop === 'mdop_stff_name') {
          this.searchForm.mdop_field = 'CONVERT(mdop_stff_name using GBK)';
        } else {
          this.searchForm.mdop_field = val.prop;
        }
      } else if (val.order == 'descending') {
        this.searchForm.mdop_sort = 'desc';
        if (val.prop === 'mdop_stff_name') {
          this.searchForm.mdop_field = 'CONVERT(mdop_stff_name using GBK)';
        } else {
          this.searchForm.mdop_field = val.prop;
        }
      } else {
        this.searchForm.mdop_sort = '';
        this.searchForm.mdop_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();

      // 升序 asc
      // 降序 desc
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.imge_url, type, updateTime);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_bor_le {
  border-left: 1px solid #3d3d3d;
}
.vd_bor_bot {
  border-bottom: 1px solid #3d3d3d;
}
</style>
